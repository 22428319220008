import { Injectable } from "@angular/core";
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from "@angular/router";
import { CommonService } from "../shared/common.service";
import { environment } from "../../environments/environment";

​
@Injectable()
export class AuthGuardMain implements CanActivate {

  constructor(private router: Router, private commonService: CommonService,private activatedroute:ActivatedRoute) { }
​
canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
  if (this.commonService.isAuthenticated()) {
    let userData = this.commonService.getUser();
    // console.log("UserData role: ", userData.role, "Incoming Role", route.data.role);
    if (route.data.role && route.data.role.indexOf(userData.role) == -1) {
      // console.log("Role Unmatched");
      this.router.navigate(["/main/dashboard"]);
      return false;
​
    } else {
      // console.log("Role Matched");
      // this.router.navigate(["/main/dashboard"]);
      return true;
    }
  }
  this.router.navigate(["/auth/login"]);
  return false;
}


//   canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
//     if (this.commonService.isAuthenticated()) {
//       let userData = this.commonService.getUser();
//       let roles = route.data.roles as Array<string>;
//       console.log(roles)
//       console.log("UserData role: ", userData.role, "Incoming Role", route.data.role);
//       if (route.data.role && route.data.role.indexOf(userData.role) == -1) {
//         // console.log("Role Unmatched");
//         this.router.navigate(["/main/dashboard"]);
//         return true;
// ​
//       } else {
//         console.log("Role Matched");
//         this.router.navigate(["/main/dashboard"]);
//         return true;
//       }
//     }
//     this.router.navigate(["/auth/login"]);
//     return false;
//   }


}