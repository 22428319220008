import { Injectable } from '@angular/core';
import { NetworkService } from '../../shared/network.service';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BrandModelService {
 

  constructor(private networkservice:NetworkService) { }
// Brands Apis start
  getBrandListApi(query:any) {
    return this.networkservice.get("api/make"+query,null,null,'bearer');
  }

  postBrandApi(body:any) {
    return this.networkservice.post("api/make",body,null,'bearer');
  }

  editBrandApi(body:any) {
    return this.networkservice.put("api/make",body,null,'bearer');
  }

  deleteBrandApi(query:any) {
    return this.networkservice.delete("api/make/"+ query,null,null,'bearer');
  }
// Brands Apis end

// Model Apis start
  getModelListApi() {
    return this.networkservice.get("api/model",null,null,'bearer');
  }
  getModelByMakeIdApi(query:any) {
    return this.networkservice.get("api/model?"+query,null,null,'bearer');
  }

  postModelApi(body:any) {
    return this.networkservice.post("api/model",body,null,'bearer');
  }

  editModelApi(body:any) {
    return this.networkservice.put("api/model",body,null,'bearer');
  }

  deleteModelApi(query:any) {
    return this.networkservice.delete("api/model/"+ query,null,null,'bearer');
  }

  uploadImage(image: File) {
    const formData = new FormData();
    formData.append("image", image);
    return this.networkservice.uploadImages("api/s3upload/image-upload-sync", formData, null, "bearer");
  }

  // Model Apis end
}
