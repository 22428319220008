import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { CommonService } from '../../shared/common.service';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import Swal from 'sweetalert2';
import {environment} from '../../../environments/environment'
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  admin:boolean = false;
  dispatcher:boolean = false
  formSubmitted: boolean = false;
  errorMsg: string = "Ok";
  headers = [];
  adminUser: boolean = true;
  clubUser: boolean = false;
  LoginFlag: boolean = true;
  ResetFlag: boolean = false;
  Loginmessage: string = "";
  isLoading: boolean=false;
  constructor(private fb: FormBuilder,
    private authServices: AuthService,
    private commonService: CommonService,
    private router: Router) { }

  ngOnInit() {
    this.initialiseForms();
  }
  initialiseForms() {
    this.loginForm = this.fb.group({
      userName: ["", [Validators.required]],
      password: ["", [Validators.required]]
    });
  }
  
  loginFN() {
    this.router.navigate(['main/dashboard'])
  }

clicked1() {
console.log('1')
this.admin = true;
this.dispatcher = false;
if(this.admin == true) {
  document.getElementById('id1').style.border = "1px solid rgb(28, 208, 191)";      /* blue */
  document.getElementById('id2').style.border = "1px solid #9c9797";       /* grey */    
}
}

clicked2() {
  console.log('2  ')
  this.dispatcher = true;
  this.admin = false;
  if(this.dispatcher==true) {
    document.getElementById('id1').style.border = "1px solid #9c9797";    /* grey */
    document.getElementById('id2').style.border = "1px solid rgb(28, 208, 191)";     /* blue */  
  }
  }

  login() {
    console.log("admin func Called")
    this.formSubmitted = true;
    if (this.loginForm.valid) { 
      let req = {
        email: this.loginForm.controls["userName"].value,
        password: this.loginForm.controls["password"].value
      };
      this.isLoading = true;
      this.authServices.validateLogin(req).subscribe((res: HttpResponse<any>) => {
        this.isLoading = false;
        if ((res["message"] = "Success")) {
          this.commonService.setStorage("token", res.headers.get('Authorization'));
          // this.commonService.setStorage("ad",environment.adminToken)
          console.log(environment.adminToken)
          let userData = this.commonService.getUser();
          this.router.navigate(["main/dashboard"]);
          this.admin = false;
        } else {
        }
      },
        err => {
          this.isLoading = false;
        });
    }
  }

  dispatcherLogin() {
    console.log("DISP func called")
    this.formSubmitted = true;
    if (this.loginForm.valid) { 
      let req = {
        email: this.loginForm.controls["userName"].value,
        password: this.loginForm.controls["password"].value
      };
      this.isLoading = true;
      this.authServices.dispatcherLoginApi(req).subscribe((res: HttpResponse<any>) => {
        this.isLoading = false;
        if ((res["message"] = "Success")) {
          this.commonService.setStorage("token", res.headers.get('Authorization'));
          // this.commonService.setStorage("disp",environment.dispatcherToken)
          let userData = this.commonService.getUser();
          this.router.navigate(["main/dashboard"]);
          this.dispatcher = false;
        } else {
        }
      },
        err => {
          this.isLoading = false;
        });

    }


  }

  loginFun() {
    if(this.admin ==false &&this.dispatcher ==false){
      Swal.fire("Please select type")
    }
    if(this.admin ==true) {
      this.login()
    } 
    if(this.dispatcher ==true)
    this.dispatcherLogin()
  }
} 
