import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login.component';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { SpinnerComponent } from '../../shared/spinner/spinner.component';
import { SharedModule } from '../../shared/shared.module';
const router: Routes = [{ path: "", component: LoginComponent }];

@NgModule({
  imports: [CommonModule,SharedModule, RouterModule.forChild(router), FormsModule, ReactiveFormsModule, HttpClientModule],
  declarations: [LoginComponent]
})
export class LoginModule { }
