import { Injectable } from "@angular/core";
import { NetworkService } from "../../shared/network.service";


@Injectable()
export class UserMgmtService {
  constructor(private _networkService: NetworkService) {}

  getUserListApi(query) {
    return this._networkService.get(
      "api/users/userList?" + query,
      null,
      null,
      "bearer"
    );
  }
  updateUserStatus(user: any) {
    return this._networkService.put("api/users/", user, null, "bearer");
  }
  createUserApi(body: any) {
    return this._networkService.post("api/users",body, null, "bearer");
  }
  createOtpApi(body:any) {
    return this._networkService.post("api/otp/create",body, null, "bearer");
  }
  verifyOtpApi(body:any) {
    return this._networkService.post("api/otp/verify",body, null, "bearer");
  }
  getMemberShipApi(query:any) {
    return this._networkService.get("api/membership/userplan?"+query,null, null, "bearer");
  }

  getCarMakerApi(query:any) {
    return this._networkService.get("api/make?"+query,null, null, "bearer");
  }
  getCarModelApi(query:any) {
    return this._networkService.get("api/model?"+query,null, null, "bearer");
  }
  createCarModelApi(body:any) {
    return this._networkService.post("api/carmodel",body, null, "bearer");
  }
}
