import { Component, OnInit, Output,EventEmitter } from '@angular/core';
@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {

  @Output() spinners:EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

}
