import { Component, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserMgmtService } from '../user.service';
import { Route, ActivatedRoute, Router } from '@angular/router';
import { ModalDismissReasons, NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import Swal from  'sweetalert2';

import { BrandModelService } from '../../brand-model-management/brand-model.service';
@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css']
})
export class AddUserComponent implements OnInit {
  isLoading:boolean = true;
  otpVal:any;
  toShow:boolean = false;
  createdUserId;
  carArray = [];
  isLinear = false;
  otpToken:string ="0";
  userId;
  brandData;
  modelData;
  heading;
  userData;
  tp:boolean = false;
  footname = "Next";
  selectedTab: string = "";
  Form1: FormGroup;
  Form2: FormGroup;
  addCarForm: FormGroup;
  Form3: FormGroup;
  emailPattern = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;
  minLengthPattern = /^.{4,}$/;
  onlyNumberPattern= /^\d{10,}$/;
  modelOptions: NgbModalOptions = {
    backdrop: "static",
    keyboard: false
  };
  closeResult: string;
  private modalRef: NgbModalRef;
  constructor(private router:Router,private brandmodelservice:BrandModelService,private modalService: NgbModal,private route:ActivatedRoute,private formBuilder: FormBuilder,private userservice:UserMgmtService) { }

  ngOnInit() {

    this.userId = this.route.snapshot.params["userId"];
    if (this.userId) {
          this.tp = true;
      this.getUserList();
     
      this.heading = "Edit User"
      this.footname="Skip"
    }
    this.initializeForm();
    this.getBrandList();
  }

  initializeForm() {
    this.Form2 = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      password: [""],
      name: ["", [Validators.required, Validators.pattern(this.minLengthPattern)]],
      mobile: ["",[ Validators.required,Validators.pattern(this.onlyNumberPattern)]],
    })
    this.addCarForm = this.formBuilder.group({
      brand: [''],
      model: [""],
      license: ["", [Validators.required, Validators.pattern(this.minLengthPattern)]],
    })
  }

  // create carModels and brand 

  getBrandList() {
    let query = "";
    this.isLoading = true;
    this.brandmodelservice.getBrandListApi(query).subscribe(res=> {
      this.isLoading = false;
      if((res['statusCode'] =='Success')) {
        this.brandData = res['data']['makeList'];
        console.log(this.brandData);
      } else {
        this.brandData = [];
      }
    },
    err => {
      this.isLoading = false;
    })
  }

  getModel() {
    let brand= this.addCarForm.controls.brand.value;
    let makeId = brand['makeId'];
    let query = "makeId="+ makeId;
     console.log(query)
    // this.isLoading = true;
    this.brandmodelservice.getModelByMakeIdApi(query).subscribe(res=> {
      this.isLoading = false;
      if((res['statusCode'] =='Success')) {
        this.modelData = res['data']['modelList'];
        console.log(this.modelData);
      } else {
        this.modelData = [];
      }
    },
    err => {
      this.isLoading = false;
    })
  }

  getUserList() {
    let query = "userId=" + this.userId;
    this.isLoading = true;
    this.userservice.getUserListApi(query).subscribe(
      res => {
        this.isLoading = false;
        if ((res["message"] = "Success")) {
          this.userData = res["data"]["userList"][0];
          console.log(this.userData['mobile'])  
          this.updateUserData();
          console.log(this.userData,"EDIT")
        } else {
          this.userData = [];
        }
        this.isLoading = false;
      },
      err => {
        this.isLoading = false;
      }
    );
  }

  updateUserData() {
    this.Form2.controls["mobile"].setValue(this.userData["mobile"]);
    this.Form2.controls["name"].setValue(this.userData["fullName"]);
    this.Form2.controls["email"].setValue(this.userData["email"]);
  }

  createOtp() {
    this.isLoading= true;
    let reqObj ={};
    reqObj['mobile'] = "+1"+this.Form2.controls.mobile.value;
    reqObj['type'] = 'UR'
    console.log(reqObj)
    this.userservice.createOtpApi(reqObj).subscribe (
      data => {
        this.isLoading = false;
        if ((data["message"] = "Success")) {
          Swal.fire("Success", "Otp sent to number!", "success");
        } else {
          Swal.fire("Error", data.data, "error");
        }
      },
      err => {
        this.isLoading = false;
        Swal.fire("Error", err.error.data, "error");
      }
    );
  }
  onOtpChange(event) {
    this.otpVal = parseInt(event);
    console.log(event)
  }
  verifyOtp() {
    this.isLoading= true;
    let reqObj ={};
    reqObj['mobile'] = "+1"+this.Form2.controls.mobile.value;
    reqObj['type'] = 'UR';
    reqObj['otp'] = this.otpVal;
    console.log(reqObj)
    this.userservice.verifyOtpApi(reqObj).subscribe (
      res => {
        this.isLoading = false;
        if ((res["message"] = "Success")) {
          console.log(res['data']['token'])
          this.otpToken = res['data']['token'];
          console.log(this.otpToken)
          Swal.fire("Success", "Otp verified successfully!", "success");
        } else {
          Swal.fire("Error", res['data'].data, "error");
        }
      },
      err => {
        this.isLoading = false;
        Swal.fire("Error", err.error.data, "error");
      }
    );
  }

  addCarArray() {
    this.carArray.push(this.addCarForm.value)
    console.log(this.carArray,"cararray")
    this.addCarForm.reset();
  }
  deleteCar(i) {
    this.carArray.splice(i, 1);
  }

getFinalCarArray( ) {
  let array =[];
  console.log(this.carArray,"Cararrayfrom finarray")
  // for (let i=0;i<this.carArray.length;i++) {
  //   array.push(
  //     {
  //       "makeId":i['model']['makeId'],
  //       "modelId":i['model']['modelId'],
  //       "license":i['license']
  //     }
  //     )
  // }

  this.carArray.forEach(element => {
    let objdata ={
      makeId:element['model']['makeId'],
      modelId:element['model']['modelId'],
      licensePlate:element['license']
    }
    array.push(objdata)
console.log(objdata,"Obj")
  });
  
  return array;
}

  addCars() {
    
    // this.isLoading= true;
    let Obj = this.getFinalCarArray();
    console.log(Obj,"finalS")
   let finalres = {} ;
    finalres['carModels'] = Obj;
    finalres['userId'] =this.createdUserId; 
    console.log(finalres,"finalResponse")
    this.userservice.createCarModelApi(finalres).subscribe (
      data => {
        this.isLoading = false;
        if ((data["message"] = "Success")) {
          Swal.fire("Success", "Cars added successfully!", "success");
          this.router.navigate(["../main/user-management/view-user"])
        } else {
          Swal.fire("Error", data.data, "error");
        }
      },
      err => {
        this.isLoading = false;
        Swal.fire("Error", err.error.data, "error");
      }
    );
  }


  submit() {
    this.isLoading= true;
    let reqObj ={};

    reqObj['mobile'] ="+1"+this.Form2.controls.mobile.value;
    reqObj['userType'] = 'app';
    reqObj['deviceToken'] = 'ejwijqeiqwe4j12b4j21jk';
    reqObj['otpToken'] = parseInt(this.otpToken);
    reqObj['fullName'] =  this.Form2.controls.name.value;
    reqObj['email'] =  this.Form2.controls.email.value;
    reqObj['password'] =  this.Form2.controls.password.value;
    console.log(reqObj)
    this.userservice.createUserApi(reqObj).subscribe (
      res => {
        this.isLoading = false;
        if ((res["message"] = "Success")) {
          Swal.fire("Success", "User created successfully!", "success");
          this.toShow = true;
        this.createdUserId = res['data']['userId'];
        console.log(this.createdUserId,"userIdd")
        } else {
          Swal.fire("Error", res.data, "error");
        }
      },
      err => {
        this.isLoading = false;
        Swal.fire("Error", err.error.data, "error");
      }
    );
  }

  submitEdit() {
    this.isLoading= true;
    let reqObj ={};
    reqObj['mobile'] = this.Form2.controls.mobile.value;
    reqObj['type'] = 'UR';
    reqObj['otpToken'] = parseInt(this.otpToken);
    reqObj['fullName'] =  this.Form2.controls.name.value;
    reqObj['email'] =  this.Form2.controls.email.value;
    reqObj['password'] =  this.Form2.controls.password.value;
if(reqObj['otpToken'] ==0) {
  console.log("Entered")
  delete reqObj['otpToken'];
}
    console.log(reqObj)
    // this.userservice.createUserApi(reqObj).subscribe (
    //   data => {
    //     this.isLoading = false;
    //     if ((data["message"] = "Success")) {
    //       Swal.fire("Success", "User created successfully!", "success");
    //     } else {
    //       Swal.fire("Error", data.data, "error");
    //     }
    //   },
    //   err => {
    //     this.isLoading = false;
    //     Swal.fire("Error", err.error.data, "error");
    //   }
    // );
  }

  navigateFunc() {
    this.isLoading = true;
    setTimeout(()=>{    //<<<---    using ()=> syntax
      this.isLoading =false;
      this.router.navigate(["../main/user-management/view-user"])
 }, 1000);


  }

 
  
  updateUserStatusModal(row, content, btn) {
    btn && btn.parentElement && btn.parentElement.parentElement && btn.parentElement.parentElement.blur();
    this.userData = row;
    this.modalRef = this.modalService.open(content, this.modelOptions);
    this.modalRef.result.then(
      result => {
        this.closeResult = `Closed with: ${result}`;
      },
      reason => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

    // addUser() {
  //   console.log("ADD DIspatcher")
  //   let reqObj = {
  //     email: this.signUpForm.value.email,
  //     password: this.signUpForm.value.password,
  //     mobile:this.signUpForm.value.mobile,
  //     fullName:this.signUpForm.value.fullName
  //   };
  //   if (this.selectedTab == 'add' && this.AddDispatcherForm.valid) {
  //     this.isLoading = true;
  //     this.dispatcherService.postDispatcher(reqObj).subscribe(data => {
  //       this.isLoading = false;
  //       if (data["message"] == "Success") {
  //         Swal.fire("Success", "Dispatcher created successfully!", "success");
  //         this.getDispatcherList();
  //         this.closeNav();
  //         this.AddDispatcherForm.reset();
  //       } else {
  //         Swal.fire("Error", data.data, "error");
  //       }
  //     }, err => {
  //       this.isLoading = false;
  //     })
  //   } else {
  //     this.editDispatcher(reqObj)
  //   }

  // }
  // END ----- Update user status block
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

}
