import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MainRoutingModule } from './main-routing.module';
import { MainComponent } from './main.component';
import { FullComponent } from '../layouts/full/full.component';
import { AppSidebarComponent } from '../layouts/full/sidebar/sidebar.component';
import { AppHeaderComponent } from '../layouts/full/header/header.component';
import { SharedModule } from '../shared/shared.module';
import { UserManagementModule } from './user-management/user-management.module';

@NgModule({
  declarations: [MainComponent,FullComponent,AppSidebarComponent,AppHeaderComponent],
  imports: [
    CommonModule,
    MainRoutingModule,
    UserManagementModule,
    SharedModule,
  ]
})
export class MainModule { }
