import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthComponent } from './auth.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthService } from './auth.service';
import { routing } from './auth-routing.module';
import { LoginModule } from './login/login.module';



@NgModule({
  imports: [CommonModule,LoginModule, routing, FormsModule, ReactiveFormsModule],
  declarations: [AuthComponent],
  providers: [AuthService]
})
export class AuthModule { }
