import { Injectable } from "@angular/core";
import { CommonService } from "../common.service";

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
}


const MENUITEMS = [
  // { state:'dashboard', name: 'Dashboard', type: 'link', icon: 'av_timer' },
  { state:'dashboard', name: 'Dashboard', type: 'link', icon: 'av_timer' },
  { state: 'user-management/view-user', type: 'link', name: 'Users', icon: 'person' },
  { state: 'provider-management', type: 'link', name: 'Provider', icon: 'assistant' },
  { state: 'subscription-management/view-subscription', type: 'link', name: 'Subscriptions', icon: 'assistant' },
  { state: 'brand-model-management', type: 'link', name: 'Brand/Model', icon: 'assistant' },
  { state: 'key-management/view-key', type: 'link', name: 'Keys', icon: 'assistant' },
  { state: 'vendor-management', type: 'link', name: 'Vendor', icon: 'assistant' },
  { state: 'dispatcher-management/view-dispatcher', type: 'link', name: 'Dispatcher', icon: 'assistant' },
  { state: 'service-management/view-service', type: 'link', name: 'Service', icon: 'assistant' },
  { state: 'booking-management/view-booking', type: 'link', name: 'Bookings', icon: 'assistant' },
  { state: 'setting', type: 'link', name: 'Settings', icon: 'assistant' },
];

const DISPATCHERITEMS = [
  { state:'dashboard', name: 'Dashboard',role:['admin'], type: 'link', icon: 'av_timer' },
  { state: 'user-management', type: 'link', name: 'Users', icon: 'person' },
  { state: 'booking-management', type: 'link', name: 'Bookings', icon: 'assistant' },
];

@Injectable()
export class MenuItems {

  constructor(
    private commonservice:CommonService) {}
  getMenuitem(): Menu[] {
    let userDetails = this.commonservice.getUser();
    if(userDetails.role =='admin') {
      return MENUITEMS;
    }
 else {
   return DISPATCHERITEMS;
 }
  }

}
