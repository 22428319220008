import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserManagementRoutingModule } from './user-management-routing.module';
import { UserManagementComponent } from './user-management/user-management.component';
import { ViewUserComponent } from './view-user/view-user.component';
import { SharedModule } from '../../shared/shared.module';
import { UserMgmtService } from './user.service';
import { NgOtpInputModule } from  'ng-otp-input';
import { AddUserComponent } from './add-user/add-user.component';
import { ReactiveFormsModule } from '@angular/forms';
// import { AddButtonComponent } from '../../shared/add-button/add-button.component';

@NgModule({
  declarations: [UserManagementComponent, ViewUserComponent, AddUserComponent],
  imports: [
    CommonModule,
    UserManagementRoutingModule,SharedModule,NgOtpInputModule,ReactiveFormsModule
  ],
  providers:[UserMgmtService]
})
export class UserManagementModule { }
