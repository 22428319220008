import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UserManagementComponent } from './user-management/user-management.component';
import { ViewUserComponent } from './view-user/view-user.component';
import { AuthGuardMain } from '../../auth/auth-guard.service';
import { AddUserComponent } from './add-user/add-user.component';

const routes: Routes = [
  {path:"",component:UserManagementComponent,
  canActivate: [AuthGuardMain],
  data: { role: ["admin", "dispatcher"] },
children:[
  { path: '', redirectTo: '/main/user-management/view-user', pathMatch: 'full' },
  {path:"view-user",component:ViewUserComponent},
  {path:"add-user",component:AddUserComponent},
  {path:"edit-user/:userId",component:AddUserComponent}
]
}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserManagementRoutingModule { }
