import { Injectable } from '@angular/core';
import { NetworkService } from '../shared/network.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private networkService: NetworkService) { }
  validateLogin(req: any) {
    return this.networkService.login("api/admins/login", req, null, null);
  }

  dispatcherLoginApi(req: any) {
    return this.networkService.login("api/admins/login", req, null, null);
  }
}
