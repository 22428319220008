import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../../shared/common.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: []
})
export class AppHeaderComponent implements OnInit{
  constructor(private commonService: CommonService) { }
  
  ngOnInit() {}

  logoutUser() { 
      this.commonService.logOut();
  }



}

