import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './main.component';
import { FullComponent } from '../layouts/full/full.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthGuardMain } from '../auth/auth-guard.service';


const routes: Routes = [

  {
    path: "main",
    component: MainComponent,
    canActivate: [AuthGuardMain],
    children: [
      { path: "", redirectTo: "/main/dashboard", pathMatch: "full" },
      { path: 'dashboard',loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)},
      { path: 'user-management',loadChildren: () => import('../main/user-management/user-management.module').then(m => m.UserManagementModule)},
      { path: 'provider-management', loadChildren: () => import('../main/provider-management/provider-management.module').then(m => m.ProviderManagementModule)},
      { path: 'brand-model-management', loadChildren: () => import('../main/brand-model-management/brand-model-management.module').then(m => m.BrandModelManagementModule),data: { role: ["admin"] }},
      { path: 'subscription-management', loadChildren: () => import('../main/subscription-management/subscription-management.module').then(m => m.SubscriptionManagementModule),data: { role: ["admin"] }},
      { path: 'key-management', loadChildren: () => import('../main/key-management/key-management.module').then(m => m.KeyManagementModule),data: { role: ["admin"] }},
      { path: 'dispatcher-management', loadChildren: () => import('../main/dispatcher-management/dispatcher-management.module').then(m => m.DispatcherManagementModule),data: { role: ["admin"] }},
      { path: 'service-management', loadChildren: () => import('../main/service-management/service-management.module').then(m => m.ServiceManagementModule),data: { role: ["admin"] }},
      { path: 'vendor-management', loadChildren: () => import('../main/vendor-management/vendor-management.module').then(m => m.VendorManagementModule),data: { role: ["admin", "dispatcher"] }},
      { path: 'booking-management', loadChildren: () => import('../main/booking-management/booking-management.module').then(m => m.BookingManagementModule),data: { role: ["admin", "dispatcher"] }},
      { path: 'setting', loadChildren: () => import('../main/setting-management/setting-management.module').then(m => m.SettingManagementModule),data: { role: ["admin"] }},
      // {
      //   path: "setting-management",
      //   component: SettingManagementComponent,
      // },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MainRoutingModule { }
