import { Routes, RouterModule } from "@angular/router";
import { AuthComponent } from "./auth.component";
import { ModuleWithProviders } from "@angular/compiler/src/core";
import { LoginComponent } from "./login/login.component";

export const routes: Routes = [
  {
    path: "auth",
    component: AuthComponent,
    children: [
      { path: "", redirectTo: "/auth/login", pathMatch: "full" },
      { path: "login", loadChildren:"./login/login.module#LoginModule"},
    //   {
    //     path: "forgotPassword/:id/:token",
    //     component: ForgotPasswordComponent
    //   },
      // { path: "**", redirectTo: "/auth/login" }
    ]
  }
];

export const routing: ModuleWithProviders = RouterModule.forChild(routes);
