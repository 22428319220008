import { Component, OnInit } from "@angular/core";
// import { NgbModal, NgbModalRef, NgbModalOptions, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { FormBuilder, FormGroup, Validators, FormControl } from "@angular/forms";
import { NgbModal, NgbModalRef, NgbModalOptions, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import Swal from "sweetalert2";
import { UserMgmtService } from "../user.service";
import {CommonService} from "../../../shared/common.service";
import { Router } from "@angular/router";

@Component({
  selector: 'app-view-user',
  templateUrl: './view-user.component.html',
  styleUrls: ['./view-user.component.scss']
})
export class ViewUserComponent implements OnInit {
  userDetails;
  heading;
  membershipList;
  localStore;
  details;
  role;
  selectedTab: string = "";
  signUpForm: FormGroup;
  emailPattern = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/;
  minLengthPattern = /^.{6,}$/;
  onlyNumberPattern= /^\d{10,}$/;
  query: string;
  statusFormSubmitted: boolean = false;
  statusForm: FormGroup;
  statusList: Array<object> = [
    { id: "active", name: "Active" },
    { id: "blocked", name: "Blocked" },
    { id: "suspended", name: "Suspend" }
  ];

  // END - Update user variable
  // filters
  searchStartDate: string;
  searchEndDate: string;
  searchUserStatus: string = "all";
  searchBy: string = "all";
  searchText: string;
// filters end

  userList = [];
  loadingIndicator: boolean = true;
  reorderable: boolean = true;
  pageLimit: number = 10;
  userSearchType: number = 0;
  minDate: any;
  maxDate: any;
  isLoading: boolean=false;
  modelOptions: NgbModalOptions = {
    backdrop: "static",
    keyboard: false
  };
  closeResult: string;
  private modalRef: NgbModalRef;

  constructor(private userMgmtService: UserMgmtService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private commonservice:CommonService,private route:Router) { }

  ngOnInit() {
    this.localStore = this.commonservice.getUser();
    console.log(this.localStore,"ROLE")
    this.role = this.localStore['role']
    this.getUserList();
    this.initializeForm();
  }

  queryParams() {
    let query = "";
    if (this.searchUserStatus && this.searchUserStatus != "all") query = query + "status=" + this.searchUserStatus+"&";
    
    if (this.searchBy && this.searchBy == "mobile" && this.searchText && this.searchText !== "") {
      query = query + "mobile=" + this.searchText.trim()+"&";
    }
    if (this.searchBy && this.searchBy == "email" && this.searchText && this.searchText !== "") {
      query = query + "email=" + this.searchText.trim()+"&";
    }
    if (this.searchStartDate && this.searchStartDate != "") {
      // convert to epoch time
      let startDate = new Date(this.searchStartDate);
      query = query + "startDate=" + Math.floor(startDate.getTime() / 1000);
    }
    if (this.searchEndDate && this.searchEndDate != "") {
      // convert to epoch time
      let endDate = new Date(this.searchEndDate);
      endDate.setDate(endDate.getDate() + 1);
      query = query + "&endDate=" + Math.floor(endDate.getTime() / 1000);
    }
    return query;
  }
  // GET USERS (By Default ALL)
  getUserList() {
    let queryParam = "";
    var queryValue = this.queryParams();
    this.isLoading = true;
    this.userMgmtService.getUserListApi(queryValue).subscribe (
      res => {
        this.isLoading = false;
        if ((res["message"] = "Success")) {
          this.userList = res["data"]["userList"];
          console.log(this.userList)
        } else {
          this.userList = [];
        }
        this.isLoading = false;
      },
      err => {
        this.isLoading = false;
      }
    );
  }

  onReset() {
    this.isLoading = true;
    setTimeout(() => {
      /** spinner ends after 5 seconds */
      this.isLoading= false;
    }, 2000);
    this.searchUserStatus = "",
    this.searchText = "",
    this.searchBy ="",
      (this.searchStartDate = ""),
      (this.searchEndDate = "");
    this.getUserList();
  }

  searchFiltersBtn() {
    this.getUserList();
  }

  prepareUpdateStatusForm() {
    this.statusForm = this.formBuilder.group({
      newStatus: ["", [Validators.required]]
    });
  }

  initializeForm() {
    this.signUpForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      password: [""],
      fullName: ["", [Validators.required, Validators.pattern(this.minLengthPattern)]],
      mobile: ["",[ Validators.required,Validators.pattern(this.onlyNumberPattern)]],
    });
  }


  updateUserStatusModal(row, content, btn,value) {
    btn && btn.parentElement && btn.parentElement.parentElement && btn.parentElement.parentElement.blur();
    this.userDetails = row;
    if (value =='update') {
      console.log("UPDATE")
      this.statusFormSubmitted = false;
      this.prepareUpdateStatusForm();
    }
    if(value == 'membership') {
      console.log("membership");
      this.getMemberShip(row);
    }
    this.modalRef = this.modalService.open(content, this.modelOptions);


    this.modalRef.result.then(
            result => {
        this.closeResult = `Closed with: ${result}`;
      this.userDetails = "";
      this.membershipList = "";
      },
      
      reason => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  getMemberShip (row) {
    this.statusFormSubmitted = true;
      this.isLoading = true;
     let query = "userId=" +row.userId;
     this.userMgmtService.getMemberShipApi(query).subscribe (
      res => {
        this.isLoading = false;
        if ((res["status"] = "Success")) {
          this.membershipList = res["data"]["userMembershipList"][0];
          console.log(this.membershipList)
        } else {
          this.membershipList = [];
        }
        this.isLoading = false;
      },
      err => {
        this.isLoading = false;
      }
    );

  }
  updateUserStatus(test) {
    this.statusFormSubmitted = true;
    if (this.statusForm.valid) {
      this.isLoading = true;
      let finalObj = {
        userId: this.userDetails["userId"],
        status: this.statusForm.controls.newStatus.value
      };
      this.userMgmtService.updateUserStatus(finalObj).subscribe(
        data => {
          this.isLoading = false;
          if ((data["message"] = "Success")) {
            Swal.fire("Success", "User status updated successfully!", "success");
            this.modalRef.close();
            this.getUserList();
          } else {
            Swal.fire("Error", data.data, "error");
          }
        },
        err => {
          this.isLoading = false;
          Swal.fire("Error", err.error.data, "error");
          this.modalRef.close();
          this.getUserList();
        }
      );
    }
  }

  openNav(event,value,row) {
    if(value =='add') {
      // this.heading = "Add a user"
      this.selectedTab = 'add'
      document.getElementById("mySidenav").style.width = "45%";
      event.stopPropagation();
    } 
    else{
      this.heading = "Edit a user"
      this.selectedTab = 'edit';
      this.details = row;
      document.getElementById("mySidenav").style.width = "45%";
      this.signUpForm.controls["email"].setValue(this.details["email"]);
      this.signUpForm.controls["mobile"].setValue(this.details["mobile"]);
      this.signUpForm.controls["fullName"].setValue(this.details["fullName"]);
      event.stopPropagation();
    }
  }

  closeNav() {
    document.getElementById("mySidenav").style.width = "0";
    this.signUpForm.reset();
  }

  navigateFunc() {
    this.isLoading = true;
    setTimeout(()=>{    //<<<---    using ()=> syntax
      this.isLoading =false;
      this.route.navigate(["../main/user-management/add-user"])
 }, 3000);


  }

  // addUser() {
  //   console.log("ADD DIspatcher")
  //   let reqObj = {
  //     email: this.signUpForm.value.email,
  //     password: this.signUpForm.value.password,
  //     mobile:this.signUpForm.value.mobile,
  //     fullName:this.signUpForm.value.fullName
  //   };
  //   if (this.selectedTab == 'add' && this.AddDispatcherForm.valid) {
  //     this.isLoading = true;
  //     this.dispatcherService.postDispatcher(reqObj).subscribe(data => {
  //       this.isLoading = false;
  //       if (data["message"] == "Success") {
  //         Swal.fire("Success", "Dispatcher created successfully!", "success");
  //         this.getDispatcherList();
  //         this.closeNav();
  //         this.AddDispatcherForm.reset();
  //       } else {
  //         Swal.fire("Error", data.data, "error");
  //       }
  //     }, err => {
  //       this.isLoading = false;
  //     })
  //   } else {
  //     this.editDispatcher(reqObj)
  //   }

  // }
  // END ----- Update user status block
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

}
