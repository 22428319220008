
import { MenuItems } from './menu-items/menu-items';
import { AccordionAnchorDirective, AccordionLinkDirective, AccordionDirective } from './accordion';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatFormFieldModule, MatDatepickerModule, MatInputModule, MatOptionModule, MatSelectModule, MatRadioModule, MatTableModule, MatButtonModule, MatIconModule, MatToolbarModule, MatSidenavModule, MatListModule, MatMenuModule } from '@angular/material';
import { MatTabsModule } from "@angular/material/tabs";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { MatNativeDateModule } from "@angular/material/core";
import { SpinnerComponent } from './spinner/spinner.component';
import {MatStepperModule} from '@angular/material/stepper';
import { AddButtonComponent } from './add-button/add-button.component';
@NgModule({
  declarations: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    SpinnerComponent,
    AddButtonComponent
  ],
  exports: [
    FormsModule, ReactiveFormsModule,
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatStepperModule,
    MatOptionModule,
    MatIconModule,
    MatSelectModule,
    MatTabsModule,
    MatToolbarModule,
    MatSidenavModule,
    MatRadioModule,
    MatMenuModule,
    MatTableModule,MatButtonModule,MatListModule,
    NgxDatatableModule,
    SpinnerComponent
   ],
  providers: [ MenuItems ]
})
export class SharedModule { }
